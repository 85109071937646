import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { FilterMatchMode, SortEvent } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { Table } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { Subject, takeUntil } from 'rxjs';
import { CpRoutes } from '../../../cp-route-constants';
import { ClientPortalService } from '../../../services/clientPortalService/client-portal.service';
import { OutboundService } from '../../../services/outbound-service/outbound.service';
import { PaginatorComponent } from '../paginator/paginator.component';
import { ImportsModule } from './imports';
@Component({
  selector: 'rcp-grid',
  standalone: true,
  imports: [
    ImportsModule,
    CommonModule,
    PaginatorComponent,
    ButtonModule,
    TooltipModule,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './grid.component.html',
  styleUrl: './grid.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent implements OnChanges, OnInit {
  @Input() gridData: any;
  @Input() headerColumns: any;
  @Input() first: number;
  @Input() rows: number;
  @Input() isRequiredStatus = false;
  @Input() actionButtonRequired = false;
  @Input() inquiryButtonRequired = false;
  @Input() statusIndex: number;
  @Input() actionButton: number;
  @Input() passGridName: string;
  @Input() gridFilterAndSorting: boolean;
  @Input() gridSorting: boolean;
  @Input() gridFilter: boolean;
  @Input() loading: boolean;
  @Input() gridSortData: any;
  @Input() gridFilterData: any;
  @Input() linkIcon = false;
  @Input() tableStyle: { [klass: string]: any } | null | undefined;
  @Output() onCreateInquiryClick = new EventEmitter<any>();
  @Output() headerGlobalFilter = new EventEmitter<any>();
  @Output() onGridDateClickEvent = new EventEmitter<any>();
  @Output() onRowClick = new EventEmitter<any>();
  @Output() sortChangeEvent = new EventEmitter<{
    field: string;
    orderDirection: string;
  }>();
  @ViewChild('treetable') treetable: Table | undefined;
  outboundService = inject(OutboundService);
  clientPortalSerivce = inject(ClientPortalService);
  rowNode: any;
  filter = false;
  filterIndex: number;
  totalRecords: number;
  paginatorGridDatas: any;
  selected: any;
  highlighted: any;
  dataNotFound = false;
  previousSortStates: { field: string | null; order: number | null } = {
    field: null,
    order: null,
  };
  dataNotFoundLabel = 'No records found';
  inquiryTooltipText = 'Create Inquiry';
  columnIndex: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  companyName = sessionStorage.getItem('companyname');
  outboundOrderDetails: any;
  orderId: string;
  lineNumber: string;
  prodDesc: string;
  isEditItemEnabled: boolean;
  isCancelItemEnabled: boolean;
  gridUnderLineText = 'grid-text-underline';
  end: number;
  start: number;
  constructor(
    private readonly cdf: ChangeDetectorRef,
    private readonly router: Router
  ) {}
  ngOnInit(): void {
    this.checkEventsAccessStatus();
  }
  ngOnChanges() {
    this.handleParentEvent(this.first, this.rows);
  }
  onSort(event: SortEvent) {
    const { field, order } = event;
    if (
      this.previousSortStates.field !== field ||
      this.previousSortStates.order !== order
    ) {
      this.previousSortStates.field = field;
      this.previousSortStates.order = order;
      const orderDirection = order === 1 ? 'asc' : 'desc';
      this.sortChangeEvent.emit({ field, orderDirection });
    }
  }
  applyColumnFilter(event: any, columnField: string) {
    const filterValue = (event.target as HTMLInputElement).value.trim();
    if (filterValue) {
      try {
        this.treetable.filter(filterValue, columnField, 'contains');
        this.headerGlobalFilter.emit({ event, filterValue, columnField });
        console.log(
          `Filter applied with value '${filterValue}' on column '${columnField}'`
        );
      } catch (error) {
        console.error('Error applying column filter:', error);
      }
    } else {
      try {
        this.treetable.filter('', columnField, 'contains');
        this.headerGlobalFilter.emit({ event, filterValue: '', columnField });
        console.log(`Filter reset for column: ${columnField}`);
      } catch (error) {
        console.error('Error resetting column filter:', error);
      }
    }
  }
  applyFilter(event: any, index: number, columnField: string) {
    const filterValue = (event.target as HTMLInputElement).value;
    // Toggle filter display for the specific column
    if (this.filterIndex === index && this.filter) {
      this.filter = false;
      this.treetable.reset(); // Reset table data
      this.treetable.filter('', FilterMatchMode.CONTAINS, columnField); // Clear filter for the column
    } else {
      this.treetable.reset(); // Reset table data
      this.treetable.filter(filterValue, FilterMatchMode.CONTAINS, columnField); // Apply filter for the column
      this.filter = true;
      this.filterIndex = index;
    }
  }
  // Handle paginator event from parent component
  handleParentEvent(first?: any, rows?: any) {
    this.dataNotFound = false;
    if (rows % 1000 !== 0) {
      this.start = first % 1000;
    } else {
      this.start = 950;
    }
    if (rows % 1000 !== 0) {
      this.end = rows % 1000;
    } else {
      this.end = rows;
    }
    this.rows = rows;
    if (this.gridData.length !== 0) {
      this.paginatorGridDatas = this.gridData.slice(this.start, this.end);
      if (this.paginatorGridDatas.length === 0) {
        this.paginatorGridDatas = this.gridData.slice(0, 50);
      }
      this.dataNotFound = false;
      this.cdf.detectChanges();
    } else {
      this.paginatorGridDatas = [];
      if (this.gridData.length === 0 && !this.loading) {
        this.dataNotFound = true;
        this.cdf.detectChanges();
      }
      this.cdf.detectChanges();
    }
  }
  // Empty paginatorGridDatas
  emptyGridOnReset() {
    this.paginatorGridDatas = [];
  }
  //This function is for date click event
  onNodeClick(event: any, index: any, data: any) {
    this.columnIndex = index;
    if (
      !isNaN(Date.parse(event?.target?.innerText)) &&
      index === 1 &&
      this.passGridName === 'sla-main-page'
    ) {
      this.onGridDateClickEvent.emit(event?.target?.innerText);
    }
    //function for client sku click
    if (this.passGridName === 'inventory-list' && index === 0) {
      this.onRowClick.emit(event?.target?.innerText);
    }
    //for client inquiry view refrence id click
    if (this.passGridName === 'inquiry-list' && index === 0) {
      this.onRowClick.emit(data);
    }
  }
  handleSelect(event: any) {
    if (event.client_sku) {
      this.lineNumber = event.lineNumber;
      this.prodDesc = event.product_name;
    }
    if (event.order_id) {
      sessionStorage.setItem(
        'orderId',
        event.order_id.concat('_' + this.companyName)
      );
    }
    if (this.passGridName === 'outbound-metrics' && this.columnIndex === 0) {
      this.onRowClick.emit(event);
    }
    if (this.passGridName === 'sla-list-view' && this.columnIndex === 0) {
      this.onRowClick.emit(event);
    }
  }
  getOutboundOrderDetails(id, action) {
    // For Websource
    /* istanbul ignore next */
    this.outboundService
      .getOutboundOrderDetails({
        company_name: this.companyName,
        orderID: id,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.outboundOrderDetails = data.response;
          const skuDetail = data.response.lines.filter(
            (line) => line.lineNumber === this.lineNumber
          );
          const actionData = {
            actionType: action,
            skuDetails: skuDetail,
            orderId: this.orderId,
            distributionCenter: this.outboundOrderDetails?.distributionCentre,
            unitPrice: this.outboundOrderDetails?.lines[0]?.unitPrice,
            unitGrossPrice: this.outboundOrderDetails?.lines[0]?.unitGrossPrice,
            stockPool: this.outboundOrderDetails?.lines[0]?.stockPool,
            currencyCode: this.outboundOrderDetails?.currencyCode,
            productDesc: this.prodDesc,
          };
          this.router.navigate([CpRoutes.AddItem], {
            state: { actionData },
          });
        },
        error: () => {},
      });
  }
  checkEventsAccessStatus() {
    this.isEditItemEnabled = this.clientPortalSerivce.isAcronymAllowed(
      this.companyName,
      'EDIT'
    );
    this.isCancelItemEnabled = this.clientPortalSerivce.isAcronymAllowed(
      this.companyName,
      'CANCEL'
    );
    this.inquiryButtonRequired = this.clientPortalSerivce.isAcronymAllowed(
      this.companyName,
      'CIND'
    );
  }
  onEdit(event) {
    this.orderId = sessionStorage.getItem('orderId');
    const action = 'edit';
    this.getOutboundOrderDetails(this.orderId, action);
  }
  onDelete(event) {
    this.orderId = sessionStorage.getItem('orderId');
    const action = 'delete';
    this.getOutboundOrderDetails(this.orderId, action);
  }
  createInquiry(event, rowData) {
    this.onCreateInquiryClick.emit({ client_sku: rowData.client_sku });
  }
  // Add dynamic class for grid data
  getClassNameforData(date: any, index: number) {
    if (
      !isNaN(Date.parse(date)) &&
      index === 1 &&
      this.passGridName === 'sla-main-page'
    ) {
      return this.gridUnderLineText;
    } else if (this.passGridName === 'inventory-list' && index === 0) {
      return this.gridUnderLineText;
    } else if (this.passGridName === 'outbound-metrics' && index === 0) {
      return this.gridUnderLineText;
    } else if (this.passGridName === 'sla-list-view' && index === 0) {
      return this.gridUnderLineText;
    } else {
      return 'grid-column-text';
    }
  }
  // Convert status string to number
  convertStrToNum(value: string) {
    return Number(value?.replace('%', ''));
  }
  convertBooleanToString(value) {
    return String(value);
  }
}
